import {React,useState, useEffect, useRef } from "react";
import "./MonthlyHeader.css";
import home from '../../../assets/images/Home icon@2x.png';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Downloadprojectreport from '../../../assets/images/ViewProjectReport.svg'
import api from '../../../api_interceptor/api'




// export default function MonthlyHeader(props) {
//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const monthName = currentDate.toLocaleString('default', { month: 'long' });

//     let navigate = useNavigate(); 
//     const finalMonthly = () =>{ 
//     //   let path = `/finalpreview`; 
//     //   navigate(path);
//     }
//     return (
//         <div className="parentContainerMain d-flex justify-content-between">
//             <div className="headList">
//                 <Link to="/" className="custom-link">
//                     <img src={home} alt="" className="homeIcon" />
//                 </Link>
//                 <div className="homeHover">Home</div>
//                 <span className="monthly-Title">| {`Monthly Update - ${monthName} ${year}`}</span>
//             </div>

//             <div className="d-flex flex-wrap">                
//                 <div className="headerBtn">
//                     <button type='submit' disabled={!props.previewEnabled || props.ismonth} style={{ opacity: (!props.previewEnabled || props.ismonth) ? '0.3' : '1' }} className='sign-in-btn-header' onClick={finalMonthly}>Download File</button>
//                 </div>
//             </div>
//         </div>
//     );
// }

export default function MonthlyHeader(props) {
    const access_details = useSelector((state) => state.user.access_details);
    const company_id = useSelector((state) => state.user.companyID);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [getDropdownList, setDropdownList] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const [getSelectedMonthYear, setSelectedMonthYear] = useState('');

    const currentDate = new Date();
    let previousMonth = currentDate.getMonth() - 1;
    let year = currentDate.getFullYear();
    
    if (previousMonth === -1) {
        // If the current month is January, set the previous month to December of the previous year
        previousMonth = 11; // December
        year--; // Decrement the year
    }
    
    const monthName = new Date(year, previousMonth).toLocaleString('default', { month: 'long' });

    let navigate = useNavigate(); 
    const finalMonthly = () =>{ 
    // const fileUrl = 'http://182.18.155.188:8000/static/Accountability.docx';
    const report_url = `PlanetReport/WWW/MobilePages/PlanetXproReports.aspx?ReportID=2&CompanyID=${access_details['company_division_id']}&CompanyDivisionID=${access_details['company_division_id']}`;
    const newWindow = window.open(`/report?reportUrl=${encodeURIComponent(report_url)}`, '_blank');

    // const newWindow = window.open(report_url, '_blank');
        console.log("Company DIV ID---------------->>",access_details['company_division_id'],"COMPANY ID----------->>",company_id['value'])
    }

    const GenerateReportPopup = (e) => {
        setSelectedMonthYear(false)
        e.preventDefault();
        setShowConfirmationModal(true);  // Open the popup
        getDropdownMonth();
    };
    
    useEffect(() => {
        if (userData && userData['UserId']) {
            getDropdownMonth(userData['UserId'])
        }
    }, [userData]);

    const getDropdownMonth = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-dropdown-generate-report';
        const req_body = {
            user_id: access_details['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setDropdownList(response.data);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

        const popupRef = useRef(null); 
    
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowConfirmationModal(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        const handleGenerateClick = (selectedMonthYear) => {
            let month = null;
            let year = null;
            if (selectedMonthYear) {

                if (selectedMonthYear.includes('-')) {
                    [month, year] = selectedMonthYear.split('-');
                  } else if (selectedMonthYear.includes('/')) {
                    [month, year] = selectedMonthYear.split('/');
                  } else {
                    console.log('Invalid Month/Year format');
                  }
          
                const report_url = `PlanetReport/WWW/MobilePages/PlanetXproReports.aspx?ReportID=2&CompanyID=${access_details['company_division_id']}&CompanyDivisionID=${access_details['company_division_id']}&Month=${month}&Year=${year}&UserID=${userData['UserId']}`;
                const newWindow = window.open(`/report?reportUrl=${encodeURIComponent(report_url)}`, '_blank');

                // const newWindow = window.open(report_url, '_blank');
                    console.log("Company DIV ID---------------->>",access_details['company_division_id'],"COMPANY ID----------->>",company_id['value'])
            } else {
            }
          };
          

    return (

       
        
        <div className="py-2 d-flex justify-content-between">

        {showConfirmationModal && (
            <div className="popup">
                <div className="popup-inner-report">
                    <div className='row mb-3'>
                        <div className='col-6 text-start' style={{fontWeight:"bold", fontSize:"20px"}}>
                             Generate Report
                        </div>
                        <div className='col-6 text-end'>
                            <a onClick={() => setShowConfirmationModal(!showConfirmationModal)}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <form onSubmit={GenerateReportPopup}>
            <label className='label-text'>
                <select
                    className='mt-2 form-select'
                    value={getSelectedMonthYear}
                    required
                    onChange={(e) => {
                        const selectedValue = e.target.value;

                        // Update the dropdown list with the selected option
                        setDropdownList((prevList) =>
                            prevList.map(item =>
                                item.label === selectedValue
                                    ? { ...item, value: selectedValue }
                                    : item
                            )
                        );

                        setSelectedMonthYear(selectedValue);
                    }}
                >
                    <option value="">Select Month/Year</option>
                    {getDropdownList.length > 0 ? ( // Conditional rendering to avoid undefined
                        getDropdownList.map((item) => (
                            <option key={item.label} value={item.label}>
                                {item.label}
                            </option>
                        ))
                    ) : null
                    
                    }
                </select>
            </label>
        </form>
                <div className="center-align"> 
                      <button className="mt-3 popup-inner-generate justify-content-between" type="Button" onClick={() => handleGenerateClick(getSelectedMonthYear)}>Generate</button>
                </div>
                </div>
               
            </div>
        )}
            <div className="">
                {/* <Link to="/" className="custom-link">
                    <img src={home} alt="" className="homeIcon" />
                </Link> */}
                {/* <div className="homeHover">Home</div> */}
                <span className="monthly-Title">{`Monthly Update - ${monthName} ${year}`}</span>
            </div>

         

            {/* <div className="d-flex flex-wrap">                
                <div className="">
                    {/* <button type='submit' disabled={!props.previewEnabled || props.ismonth} style={{ opacity: (!props.previewEnabled || props.ismonth) ? '0.3' : '1' }} className='sign-in-btn-header' onClick={finalMonthly}>Download File</button> */}
                    {/* <button type='submit'  className='sign-in-btn-header' onClick={finalMonthly}>Download File</button>
                </div>
            </div> */} 

             <div className="d-flex flex-wrap">                
                <div className="">
                    {/* <button type='submit' disabled={!props.previewEnabled || props.ismonth} style={{ opacity: (!props.previewEnabled || props.ismonth) ? '0.3' : '1' }} className='sign-in-btn-header' onClick={finalMonthly}>Download File</button> */}
                   <button type='submit'  className='sign-in-btn-header' onClick={GenerateReportPopup}>Generate Report</button>
                </div>
            </div> 
        </div>
    );
}